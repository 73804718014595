import React from "react"
import Layout from "../../components/layout"
import PcpHero from "../../components/PcpHero"
import Breadcrumbs from "../../components/Breadcrumbs"
import VideoModule from "../../components/VideoModule"
import HowHubbleWorksCarousel from "../../components/HowHubbleWorksCarousel"
import SEO from "../../components/seo"
import avgProducts from "../../services/avg-products.json"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "../../styles/pages/hubble-lenses.scss"

import PcpList, { formatPcpItem } from "../../components/PcpList"
import {getHubblePricing} from "../../services/data";

const weeklyTitles = [
  "Acuvue 2 6pk",
  "Acuvue Oasys with Hydraclear Plus 6pk",
  "Acuvue Oasys with Hydraclear Plus 12pk",
  "Acuvue Oasys with Hydraclear Plus 24pk",
  "Acuvue Oasys Multifocal 6pk",
  "Acuvue Oasys for Astigmatism 6pk",
  "Acuvue Oasys with Transitions 6pk",
].map(title => title.toLowerCase())

const WeeklyPcp = ()  => {
  const schemaMarkup = [
    {
      "@context": "https://schema.org",
      "@type": "VideoObject",
      "name": "Hubble Contacts | How We Make Contact Lenses Easy",
      "description": "At Hubble, we believe buying contact lenses should be easy. That’s why we’ve made Hubble’s personalized subscription service the easiest contacts experience in the world.",
      "thumbnailUrl": "https://i.ytimg.com/vi/w4YCLxsUxyI/hqdefault.jpg",
      "uploadDate": "2023-03-20",
      "duration": "PT1M15S",
      "contentUrl": "https://www.hubblecontacts.com",
      "embedUrl": "https://www.youtube.com/embed/w4YCLxsUxyI"

    },
    {
      "@context": "https://schema.org",
      "@url": "https://hubblecontacts.com/contact-lenses/",
      "@type": "webpage",
      "name": "Hubble Contacts: Order Prescription Contacts Online and Personalize Your Subscription",
      "description": "Order contact lenses online easily with Hubble. Our high-quality and affordable prescription contact lenses get delivered directly to your door and always ship free. Try Hubble’s classic or Hydro contact lenses",
      "publisher": {
        "@type": "Organization",
        "name": "Hubble"
      }
    }
  ]
  const hubblePricing = getHubblePricing()

  let products = []
  avgProducts.forEach(product => {
    product.options.forEach((option, optionIndex) => {
      const title = option?.title?.replace(/6pk|12pk|24pk|30pk|90pk/, '')?.trim()
      const isWeekly = weeklyTitles.includes(option?.title?.toLowerCase())
      console.log('option?.title', option?.title)
      if (isWeekly) {
        const pcpItem = formatPcpItem(product, option, optionIndex)
        products.push(pcpItem)
      }
    })
  })

  const promos = [
    {
      index: 5,
      heading: "Hydro by Hubble",
      title: "Naturally Hydrating",
      text: "With their smooth edges and moisture-retaining materials, these daily lenses are designed for comfort.",
      buttonText: "Shop Hydro by Hubble",
      linkTo: "contact-lenses/hydro-by-hubble-lenses/",
      imageClass: "promo-3"
    },
    {
      index: 9,
      heading: "Hubble Contact Lenses",
      title: "Start your subscription for as little as $1.",
      text: "Hubble makes ordering contact lenses online simple and affordable.",
      buttonText: "Shop Hubble Contacts",
      linkTo: "/contact-lenses/",
      imageClass: "promo-2",
    }
  ]

  return (
    <Layout>
      <SEO
        title="Hubble Contacts: Order Prescription Contacts Online and Personalize Your Subscription"
        description="Order contact lenses online easily with Hubble. Our high-quality and affordable prescription contact lenses get delivered directly to your door. Try Hubble’s classic, Hydro, and SkyHy contact lenses."
        schemaMarkup={schemaMarkup}
      />
      <PcpHero
        heroText={<>Weekly Contact<br/> Lenses</>}
        heroImage={"none"}
        background="purple"
        bannerClass="purple promo-banner pcp"
      />
      <div className="pcp-container">
        <Breadcrumbs links={[{ to: "/", label: "Homepage" }, { label: "Weekly Contact Lenses" }]} />
        <PcpList {...{products, promos}} />
      </div>
      <HowHubbleWorksCarousel color="purple"/>
      <div className="video-container">
        <VideoModule
          type={"contacts"}
          video={"https://www.youtube.com/embed/quoNFn7dxTw"}
          image="Pages/ContactsPDP/Hubble-Contacts-PDP-Video-Still.jpg"
          alt_text="Woman unboxing a new delivery of Hubble Contacts"
        />
      </div>
      <div className="pt-5 mt-0 mt-sm-5"></div>
    </Layout>
  )
}

export default WeeklyPcp
